import {
	CloseOutlined,
	BuildOutlined,
	SearchOutlined,
	MenuOutlined,
} from '@mui/icons-material'

export const CloseIcon = CloseOutlined
export const DevToolsIcon = BuildOutlined
export const MenuIcon = MenuOutlined
export const SearchIcon = SearchOutlined
